html {
    color: #152e4d;
    font-family: 'Helvetica Neue';
}

.bg {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -2;
    min-width: 100%;
    min-height: 100%;
}

.bgc {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100vw;
    height: 100vh;
    background-color: #efcdb0;
    opacity: 0.3;
}


.page {
    height: 100vh;
}

.logo {
    position: absolute;
    top: 30px;
    right: 50px;
}

.game_logo_min {
    width: 200px;
    margin: 30px;
    margin-bottom: 150px;
}

.intro {
    font-size: 20px;
    margin: 20px 0;
}

.map {
    position: absolute;
    right: 0;
}

.btn-primary {
    background-color: #152e4d;
    border: #152e4d;
    text-transform: uppercase;
    padding: 15px;
    font-size: 20px;
    margin: 20px 0;
}

*,
*::before *::after {
    box-sizing: border-box;
}

.radios {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
}

@media (max-width: 640px) {
    .radios {
        flex-direction: column;
    }
}

.radio input {
    position: absolute;
    pointer-events: none;
    visibility: hidden;
}

.radio input:focus+label {
    background: #eeeeff;
}

.radio input:focus+label .checker {
    border-color: #6666ff;
}

.radio input:checked+label .checker {
    box-shadow: inset 0 0 0 6px #6666ff;
}

.radio label {
    display: flex;
    align-items: center;
    height: 28px;
    border-radius: 14px;
    margin: 10px;
    padding: 0 8px 0 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.radio label:hover {
    /* background: #eeeeff; */
}

.radio label:hover .checker {
    box-shadow: inset 0 0 0 2px #6666ff;
}

.radio .checker {
    width: 22px;
    height: 29px;
    border-radius: 50%;
    margin-right: 8px;
    box-shadow: inset 0 0 0 2px #ccc;
    transition: box-shadow 0.3s ease;
    background-image: url(img/r.png);
}

.radio label:hover .checker {
    box-shadow: 0 0 0 2px #152e4d;
}

.radio input:checked+label .checker {    
    box-shadow: 0 0 0 6px #152e4d;    
}

.page-inscri {
    color: #152e4d;
    font-family: 'Noir Pro';
}

.page-inscri input {
    font-size: 18px;
    padding: 20px;
    border-radius: 5px;
    margin: 20px 0;
    border: 1px solid #ccc;
    display: inline-block;
}

.page-inscri .col-6 input {
    min-width: 290px;
}

.page-inscri .col-12 input {
    min-width: 600px;
}

.page-inscri .col-6,
.page-inscri .col-12 {
    position: relative;
}

.page-inscri .badge {
    position: absolute;
    top: calc(50% - 30px);
    left: calc(50% - 0px);
    transform: translate(-50%, -50%);
    display: inline-block;
}

.page-inscri .col-6.text-start .badge {
    left: calc(50% - 170px);
}

.page-inscri .col-6.text-end .badge {
    left: calc(50% + 190px);
}

.page-questions {
    color: #152e4d;
    font-family: 'Noir Pro';
}

.page-questions h2 {
    font-family: 'Noir Pro';
    font-size: 25px;
    font-weight: bold;
}

.page-questions .q {
    margin: 0 30px;
}

.page-questions .q .radio {
    font-family: 'Noir Pro';
    font-size: 25px;
    font-weight: 400;
    margin: 10px 0;
}

.page-questions .q .radio img {
    display: inline-block;
    margin: 0 20px;
}

.page-questions .qb {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 50px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.page-questions .qb button {
    background-color: #fff;
    border: 1px solid #152e4d;
    background-color: #062954;
    color:#fff;
    border-radius: 10px;
}

.page-questions .qb button:nth-of-type(2) {
    float: right;
}

.page-result {
    color: #152e4d;
    font-family: 'Noir Pro';
}

.page-result h3 {
    font-size: 20px;
    margin: 0;
}

.page-result h1 {
    margin: 0;
    font-weight: bold;
}

.sharing {
    margin-bottom: 50px;
}

.share-button {
    display: inline;
    margin: 0 10px;
}

@media screen and (max-width: 1024px) {
    .logo {
        width: 100px;
        height: auto;
        right: 50px;
    }
    .map {
        position: absolute;
        bottom: 0;
        z-index: -1;
        max-width: 100vw;
    }
    .game_logo {
        max-width: 90vw;
    }
    .game_logo_min {
        width: 120px;
        margin: 30px;
        margin-bottom: 60px;
    }
    .page-home .col-6 {
        width: 100%;
        margin-top: 50px;
    }
    .page-inscri .col-6 {
        width: 100%;
        text-align: center !important;
    }
    .page-inscri .col-6 input {
        min-width: 300px;
        max-width: 90vw;
    }
    .page-inscri .col-12 input {
        min-width: 300px;
        max-width: 90vw;
    }
    .page-inscri .col-6.text-start .badge {
        left: calc(50% - 0px);
    }
    .page-inscri .col-6.text-end .badge {
        left: calc(50% + 0px);
    }
    .page-questions .q .radio {
        margin: 10px 0;
        font-size: 18px;
    }
    .page-questions .qb {
        position: static;
        font-size: 18px;
        padding: 10px;
    }
    .page-questions .qb button {
        font-size: 12px;
    }
    .page-result .col-6 {
        width: 100%;

    }
}

@media screen and (min-width: 1120px) {
    .page-home .col-6:nth-of-type(1) > div {
        text-align: center;
    }
    .page-home .col-6:nth-of-type(2) > div {
        text-align: center;
    }
}